"use client";
import React, { useEffect, useState, useRef } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/jsvectormap.css";
import "../../js/us-aea-en";
import { LAUNCH } from "@/types/Launch";
import mapCode from "../common/MapCode"; 

interface MapProps {
  state: LAUNCH[];
}

const MapOne: React.FC<MapProps> = ({ state }) => {
  const [isClient, setIsClient] = useState(false);
  const mapRef = useRef<any>(null);

  function mapStatesToKeys(states: any, mapCode: any) {
    return states?.map((state: any) => {
      for (const [key, value] of Object.entries(mapCode)) {
        if (value === state) {
          return key;
        }
      }
      return null;
    });
  }

  function createAvailabilityObject(keysArray: any) {
    const availabilityObject: any = {};
    keysArray?.forEach((key: any) => {
      if (key !== null) {
        availabilityObject[key] = "available";
      }
    });
    return availabilityObject;
  }

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!isClient) return;

    const keysArray = mapStatesToKeys(
      state?.map((e) => e?.state),
      mapCode,
    );
    const availabilityObject = createAvailabilityObject(keysArray);
    
    if (!mapRef.current) {
      const mapElement = document.querySelector("#mapOne");
      if (!mapElement) {
        console.error("Element #mapOne not found in the DOM.");
        return;
      }

      mapRef.current = new jsVectorMap({
        selector: "#mapOne",
        map: "us_aea_en",
        zoomButtons: true,

        regionStyle: {
          initial: {
            fill: "#C8D0D8",
          },
          hover: {
            fillOpacity: 1,
            fill: "#3056D3",
          },
        },
        regionLabelStyle: {
          initial: {
            fontFamily: "Satoshi",
            fontWeight: "semibold",
            fill: "#fff",
          },
          hover: {
            cursor: "pointer",
          },
        },

        labels: {
          regions: {
            render(code: string) {
              return code.split("-")[1];
            },
          },
        },
        series: {
          regions: [
            {
              attribute: "fill",
              scale: {
                available: "#3056D3",
              },
              values: availabilityObject,
            },
          ],
        },
      });
    } else {
      mapRef.current.series.regions[0].setValues(availabilityObject);
    }
  }, [isClient, state]);

  if (!isClient) return null;

  return (
    <div
      className="col-span-12 bg-customBackgroundColor rounded-lg  px-7.5 py-6 shadow-default dark:border-strokedark  xl:col-span-7"
    >
      <div className="h-90 mb-26">
        <div id="mapOne" className="mapOne map-btn"></div>
      </div>
    </div>
  );
};

export default MapOne;
