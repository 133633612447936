// MapCode.ts
const mapCode = {
    "US-VA": "Virginia",
    "US-PA": "Pennsylvania",
    "US-TN": "Tennessee",
    "US-ID": "Idaho",
    "US-NV": "Nevada",
    "US-TX": "Texas",
    "US-NH": "New Hampshire",
    "US-NY": "New York",
    "US-HI": "Hawaii",
    "US-VT": "Vermont",
    "US-NM": "New Mexico",
    "US-NC": "North Carolina",
    "US-ND": "North Dakota",
    "US-NE": "Nebraska",
    "US-LA": "Louisiana",
    "US-SD": "South Dakota",
    "US-DC": "District of Columbia",
    "US-DE": "Delaware",
    "US-FL": "Florida",
    "US-WA": "Washington",
    "US-KS": "Kansas",
    "US-WI": "Wisconsin",
    "US-OR": "Oregon",
    "US-KY": "Kentucky",
    "US-CO": "Colorado",
    "US-OH": "Ohio",
    "US-OK": "Oklahoma",
    "US-WV": "West Virginia",
    "US-WY": "Wyoming",
    "US-UT": "Utah",
    "US-IN": "Indiana",
    "US-IL": "Illinois",
    "US-AK": "Alaska",
    "US-NJ": "New Jersey",
    "US-ME": "Maine",
    "US-MD": "Maryland",
    "US-AR": "Arkansas",
    "US-MA": "Massachusetts",
    "US-AL": "Alabama",
    "US-MO": "Missouri",
    "US-MN": "Minnesota",
    "US-CA": "California",
    "US-IA": "Iowa",
    "US-MI": "Michigan",
    "US-GA": "Georgia",
    "US-AZ": "Arizona",
    "US-MT": "Montana",
    "US-MS": "Mississippi",
    "US-SC": "South Carolina",
    "US-RI": "Rhode Island",
    "US-CT": "Connecticut",
  };
  
  export default mapCode;
  
//   console.log(
//     "mapRef",
//     Object.values(mapRef?.current?.regions || {}).map(
//       (e: any) => e?.config?.name,
//     ),
//   );